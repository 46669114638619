<template>
  <div class="uk-container uk-margin-small-top">
    <h2 class="uk-margin-small-bottom">My Account</h2>
    <div class="uk-card uk-card-default uk-card-small uk-card-body">
      <ul class="uk-tab">
        <li
          :class="$route.name == 'trader.my-account' ? 'uk-active' : ''"
          :aria-expanded="$route.name == 'trader.my-account'"
        >
          <router-link :to="{ name: 'trader.my-account' }">Profile</router-link>
        </li>
        <li
          :aria-expanded="$route.name == 'trader.my-account.security'"
          :class="
            $route.name == 'trader.my-account.security' ? 'uk-active' : ''
          "
        >
          <router-link
            :class="`nav-link ${
              $route.name == 'trader.my-account.security' ? 'active' : ''
            }`"
            :to="{ name: 'trader.my-account.security' }"
            >Security</router-link
          >
        </li>
        <li
          :aria-expanded="$route.name == 'trader.my-account.membership'"
          :class="
            $route.name == 'trader.my-account.membership' ? 'uk-active' : ''
          "
        >
          <router-link
            :class="`nav-link ${
              $route.name == 'trader.my-account.membership' ||
              $route.name == 'trader.my-account.extend'
                ? 'active'
                : ''
            }`"
            :to="{ name: 'trader.my-account.membership' }"
            >Membership</router-link
          >
        </li>
        <li
          :aria-expanded="$route.name == 'trader.my-account.affiliate'"
          :class="
            $route.name == 'trader.my-account.affiliate' ? 'uk-active' : ''
          "
        >
          <router-link
            :class="`nav-link ${
              $route.name == 'trader.my-account.affiliate' ||
              $route.name == 'trader.my-account.extend'
                ? 'active'
                : ''
            }`"
            :to="{ name: 'trader.my-account.affiliate' }"
            >Affiliate Program</router-link
          >
        </li>
        <li
          class="uk-tab-responsive uk-active uk-hidden"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <a>Actions</a>
          <div class="uk-dropdown uk-dropdown-small" aria-hidden="true">
            <ul class="uk-nav uk-nav-dropdown"></ul>
            <div></div>
          </div>
        </li>
      </ul>

      <ul id="my-profile-content" class="uk-switcher uk-margin">
        <li class="uk-active" aria-hidden="false">
          <router-view />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {};
</script>
